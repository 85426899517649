import Rellax from "rellax";
import { mobileScreenWidth } from "./constants";

document.addEventListener("DOMContentLoaded", afterDOMContentLoaded);

function afterDOMContentLoaded() {
  document.getElementById("countdown-btn").addEventListener("click", () => {
    gtag("event", "discord-click");
    gtag("event", "conversion", {
      send_to: "AW-10861975328/e0yGCIXnmKYDEKCusrso",
    });
    fbq("track", "Lead");
  });

  if (window.innerWidth > mobileScreenWidth) {
    new Rellax(".landing-rellax");
  }
}
